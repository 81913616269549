// Tremor BarChart [v0.2.1]

"use client";

import { CaretLeft, CaretRight, ChartBar } from "@phosphor-icons/react";
import React from "react";
import {
  Bar,
  CartesianGrid,
  Label,
  BarChart as RechartsBarChart,
  Legend as RechartsLegend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  type XAxisProps,
  YAxis,
} from "recharts";
import type { AxisDomain } from "recharts/types/util/types";

import { useOnWindowResize } from "@/lib/hooks/useOnWindowResize.ts";
import { cn } from "@/lib/utils";
import {
  AvailableChartColors,
  type AvailableChartColorsKeys,
  type AvailableChartGradientsKeys,
  chartGradientColors,
  constructCategoryColors,
  getColorClassName,
  getGradientId,
  getYAxisDomain,
} from "@/lib/utils/chartUtils.ts";

//#region Shape

function deepEqual<T>(obj1: T, obj2: T): boolean {
  if (obj1 === obj2) return true;

  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1) as Array<keyof T>;
  const keys2 = Object.keys(obj2) as Array<keyof T>;

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
}

const renderShape = (
  props: any,
  activeBar: any | undefined,
  activeLegend: string | undefined,
  layout: string,
  mode: "solid" | "gradient",
  colorKey: AvailableChartColorsKeys,
  borderRadius: number | undefined = 0,
) => {
  const { name, payload, value } = props;
  let { x, width, y, height } = props;

  if (layout === "horizontal" && height < 0) {
    y += height;
    height = Math.abs(height);
  } else if (layout === "vertical" && width < 0) {
    x += width;
    width = Math.abs(width);
  }

  const fill =
    mode === "gradient" ? `url(#${getGradientId(colorKey)})` : undefined;

  const isInactive =
    activeBar || (activeLegend && activeLegend !== name)
      ? !deepEqual(activeBar, { ...payload, value })
      : false;

  const getModifiedColor = (originalClass: string) => {
    return originalClass.replace(/500/g, "200"); // Replace intensity with lighter value
  };

  const activeClass = getColorClassName(colorKey, "fill");
  const inactiveClass = getModifiedColor(activeClass);

  return (
    <rect
      x={x}
      y={y}
      width={width}
      height={height}
      fill={fill}
      className={cn({
        [activeClass]: !isInactive && mode === "solid",
        [inactiveClass]: isInactive && mode === "solid",
      })}
      rx={borderRadius}
      ry={borderRadius}
    />
  );
};

//#region Legend

interface LegendItemProps {
  name: string;
  color: AvailableChartColorsKeys;
  onClick?: (name: string, color: AvailableChartColorsKeys) => void;
  activeLegend?: string;
  mode?: "solid" | "gradient";
}

const LegendItem = ({
  name,
  color,
  onClick,
  activeLegend,
  mode = "solid",
}: LegendItemProps) => {
  const hasOnValueChange = !!onClick;
  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
    <li
      className={cn(
        // base
        "group inline-flex flex-nowrap items-center gap-1.5 whitespace-nowrap rounded px-2 py-1 transition",
        hasOnValueChange
          ? "cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800"
          : "cursor-default",
      )}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(name, color);
      }}
    >
      <span
        className={cn(
          "size-2 shrink-0 rounded-sm",
          getColorClassName(color, "bg", mode),
          activeLegend && activeLegend !== name ? "opacity-40" : "opacity-100",
        )}
        aria-hidden={true}
      />
      <p
        className={cn(
          // base
          "truncate whitespace-nowrap text-xs",
          // text color
          "text-gray-700 dark:text-gray-300",
          hasOnValueChange &&
            "group-hover:text-gray-900 dark:group-hover:text-gray-50",
          activeLegend && activeLegend !== name ? "opacity-40" : "opacity-100",
        )}
      >
        {name}
      </p>
    </li>
  );
};

interface ScrollButtonProps {
  icon: React.ElementType;
  onClick?: () => void;
  disabled?: boolean;
}

const ScrollButton = ({ icon, onClick, disabled }: ScrollButtonProps) => {
  const Icon = icon;
  const [isPressed, setIsPressed] = React.useState(false);
  const intervalRef = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    if (isPressed) {
      intervalRef.current = setInterval(() => {
        onClick?.();
      }, 300);
    } else {
      clearInterval(intervalRef.current as NodeJS.Timeout);
    }
    return () => clearInterval(intervalRef.current as NodeJS.Timeout);
  }, [isPressed, onClick]);

  React.useEffect(() => {
    if (disabled) {
      clearInterval(intervalRef.current as NodeJS.Timeout);
      setIsPressed(false);
    }
  }, [disabled]);

  return (
    <button
      type="button"
      className={cn(
        // base
        "group inline-flex size-5 items-center truncate rounded transition",
        disabled
          ? "cursor-not-allowed text-gray-400 dark:text-gray-600"
          : "cursor-pointer text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-800 dark:hover:text-gray-50",
      )}
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
        setIsPressed(true);
      }}
      onMouseUp={(e) => {
        e.stopPropagation();
        setIsPressed(false);
      }}
    >
      <Icon className="size-full" aria-hidden="true" />
    </button>
  );
};

interface LegendProps extends React.OlHTMLAttributes<HTMLOListElement> {
  categories: string[];
  colors?: AvailableChartColorsKeys[];
  onClickLegendItem?: (category: string, color: string) => void;
  activeLegend?: string;
  enableLegendSlider?: boolean;
  mode?: "solid" | "gradient";
}

type HasScrollProps = {
  left: boolean;
  right: boolean;
};

const Legend = React.forwardRef<HTMLOListElement, LegendProps>((props, ref) => {
  const {
    categories,
    colors = AvailableChartColors,
    className,
    onClickLegendItem,
    activeLegend,
    enableLegendSlider = false,
    mode = "solid",
    ...other
  } = props;
  const scrollableRef = React.useRef<HTMLInputElement>(null);
  const scrollButtonsRef = React.useRef<HTMLDivElement>(null);
  const [hasScroll, setHasScroll] = React.useState<HasScrollProps | null>(null);
  const [isKeyDowned, setIsKeyDowned] = React.useState<string | null>(null);
  const intervalRef = React.useRef<NodeJS.Timeout | null>(null);

  const checkScroll = React.useCallback(() => {
    const scrollable = scrollableRef?.current;
    if (!scrollable) return;

    const hasLeftScroll = scrollable.scrollLeft > 0;
    const hasRightScroll =
      scrollable.scrollWidth - scrollable.clientWidth > scrollable.scrollLeft;

    setHasScroll({ left: hasLeftScroll, right: hasRightScroll });
  }, [setHasScroll]);

  const scrollToTest = React.useCallback(
    (direction: "left" | "right") => {
      const element = scrollableRef?.current;
      const scrollButtons = scrollButtonsRef?.current;
      const scrollButtonsWith = scrollButtons?.clientWidth ?? 0;
      const width = element?.clientWidth ?? 0;

      if (element && enableLegendSlider) {
        element.scrollTo({
          left:
            direction === "left"
              ? element.scrollLeft - width + scrollButtonsWith
              : element.scrollLeft + width - scrollButtonsWith,
          behavior: "smooth",
        });
        setTimeout(() => {
          checkScroll();
        }, 400);
      }
    },
    [enableLegendSlider, checkScroll],
  );

  React.useEffect(() => {
    const keyDownHandler = (key: string) => {
      if (key === "ArrowLeft") {
        scrollToTest("left");
      } else if (key === "ArrowRight") {
        scrollToTest("right");
      }
    };
    if (isKeyDowned) {
      keyDownHandler(isKeyDowned);
      intervalRef.current = setInterval(() => {
        keyDownHandler(isKeyDowned);
      }, 300);
    } else {
      clearInterval(intervalRef.current as NodeJS.Timeout);
    }
    return () => clearInterval(intervalRef.current as NodeJS.Timeout);
  }, [isKeyDowned, scrollToTest]);

  const keyDown = (e: KeyboardEvent) => {
    e.stopPropagation();
    if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
      e.preventDefault();
      setIsKeyDowned(e.key);
    }
  };
  const keyUp = (e: KeyboardEvent) => {
    e.stopPropagation();
    setIsKeyDowned(null);
  };

  React.useEffect(() => {
    const scrollable = scrollableRef?.current;
    if (enableLegendSlider) {
      checkScroll();
      scrollable?.addEventListener("keydown", keyDown);
      scrollable?.addEventListener("keyup", keyUp);
    }

    return () => {
      scrollable?.removeEventListener("keydown", keyDown);
      scrollable?.removeEventListener("keyup", keyUp);
    };
  }, [checkScroll, enableLegendSlider]);

  return (
    <ol
      ref={ref}
      className={cn("relative overflow-hidden", className)}
      {...other}
    >
      <div
        ref={scrollableRef}
        //tabIndex={0}
        className={cn(
          "flex h-full",
          enableLegendSlider
            ? hasScroll?.right || hasScroll?.left
              ? "snap-mandatory items-center overflow-auto pl-4 pr-12 [scrollbar-width:none] [&::-webkit-scrollbar]:hidden"
              : ""
            : "flex-wrap",
        )}
      >
        {categories.map((category, index) => (
          <LegendItem
            // biome-ignore lint/suspicious/noArrayIndexKey: no unique keys for legend items
            key={`item-${index}`}
            name={category}
            color={colors[index] as AvailableChartColorsKeys}
            onClick={onClickLegendItem}
            activeLegend={activeLegend}
            mode={mode}
          />
        ))}
      </div>
      {enableLegendSlider && (hasScroll?.right || hasScroll?.left) ? (
        <>
          <div
            className={cn(
              // base
              "absolute bottom-0 right-0 top-0 flex h-full items-center justify-center pr-1",
              // background color
              "bg-white dark:bg-gray-950",
            )}
          >
            <ScrollButton
              icon={CaretLeft}
              onClick={() => {
                setIsKeyDowned(null);
                scrollToTest("left");
              }}
              disabled={!hasScroll?.left}
            />
            <ScrollButton
              icon={CaretRight}
              onClick={() => {
                setIsKeyDowned(null);
                scrollToTest("right");
              }}
              disabled={!hasScroll?.right}
            />
          </div>
        </>
      ) : null}
    </ol>
  );
});

Legend.displayName = "Legend";

const ChartLegend = (
  { payload }: any,
  categoryColors: Map<string, AvailableChartColorsKeys>,
  setLegendHeight: React.Dispatch<React.SetStateAction<number>>,
  activeLegend: string | undefined,
  onClick?: (category: string, color: string) => void,
  enableLegendSlider?: boolean,
  legendPosition?: "left" | "center" | "right",
  yAxisWidth?: number,
) => {
  const legendRef = React.useRef<HTMLDivElement>(null);

  useOnWindowResize(() => {
    const calculateHeight = (height: number | undefined) =>
      height ? Number(height) + 15 : 60;
    setLegendHeight(calculateHeight(legendRef.current?.clientHeight));
  });

  const filteredPayload = payload.filter((item: any) => item.type !== "none");

  const paddingLeft =
    legendPosition === "left" && yAxisWidth ? yAxisWidth - 8 : 0;

  return (
    <div
      style={{ paddingLeft: paddingLeft }}
      ref={legendRef}
      className={cn(
        "flex items-center",
        { "justify-center": legendPosition === "center" },
        {
          "justify-start": legendPosition === "left",
        },
        { "justify-end": legendPosition === "right" },
      )}
    >
      <Legend
        categories={filteredPayload.map((entry: any) => entry.value)}
        colors={filteredPayload.map((entry: any) =>
          categoryColors.get(entry.value),
        )}
        onClickLegendItem={onClick}
        activeLegend={activeLegend}
        enableLegendSlider={enableLegendSlider}
      />
    </div>
  );
};

//#region Tooltip

type TooltipProps = Pick<ChartTooltipProps, "active" | "payload" | "label">;

type PayloadItem = {
  category: string;
  value: number;
  index: string;
  color: AvailableChartColorsKeys;
  type?: string;
  payload: any;
};

interface ChartTooltipProps {
  active: boolean | undefined;
  payload: PayloadItem[];
  label: string;
  valueFormatter: (value: number) => string;
  mode?: "solid" | "gradient";
}

const ChartTooltip = ({
  active,
  payload,
  label,
  valueFormatter,
  mode = "solid",
}: ChartTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <div
        className={cn(
          // base
          "rounded-md border text-[10px] shadow-md",
          // border color
          "border-gray-200 dark:border-gray-800",
          // background color
          "bg-white dark:bg-gray-950",
          // text
        )}
      >
        <div className={cn("px-4 pt-2")}>
          <p
            className={cn(
              // text color
              "text-gray-600 dark:text-gray-50",
            )}
          >
            {label}
          </p>
        </div>
        <div className={cn("space-y-1 px-4 pt-1 pb-2")}>
          {payload.map(({ value, category, color }, index) => (
            <div
              key={`id-${index}`}
              className="flex items-center justify-between space-x-8"
            >
              <div className="flex items-center space-x-2">
                <span
                  aria-hidden="true"
                  className={cn(
                    "size-2 shrink-0 rounded-sm",
                    getColorClassName(color, "bg", mode),
                  )}
                />
                <p
                  className={cn(
                    // base
                    "whitespace-nowrap text-right",
                    // text color
                    "text-gray-900 dark:text-gray-300 capitalize",
                  )}
                >
                  {category}
                </p>
              </div>
              <p
                className={cn(
                  // base
                  "whitespace-nowrap text-right font-medium tabular-nums",
                  // text color
                  "text-gray-600 dark:text-gray-50",
                )}
              >
                {valueFormatter(value)}
              </p>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

// #region RotatedXAxisTick
const RotatedXAxisTick = ({
  x,
  y,
  payload,
  formatXAxisTick,
}: {
  x: number;
  y: number;
  payload: any;
  formatXAxisTick?: XAxisProps["tickFormatter"];
}) => (
  <text
    x={x}
    y={y}
    dy={10}
    textAnchor="end"
    transform={`rotate(-45, ${x}, ${y})`}
    className="fill-gray-500 text-[9px] dark:fill-gray-300"
  >
    {formatXAxisTick ? formatXAxisTick(payload.value, 0) : payload.value}
  </text>
);

//#region BarChart

type BaseEventProps = {
  eventType: "category" | "bar";
  categoryClicked: string;
  [key: string]: number | string;
};

type BarChartEventProps = BaseEventProps | null | undefined;

interface BarChartProps extends React.HTMLAttributes<HTMLDivElement> {
  allowDecimals?: boolean;
  autoMinValue?: boolean;
  barCategoryGap?: string | number;
  borderRadius?: number;
  categories: string[];
  colors?: AvailableChartColorsKeys[];
  customTooltip?: React.ComponentType<TooltipProps>;
  data: Record<string, any>[];
  enableLegendSlider?: boolean;
  index: string;
  intervalType?: "preserveStartEnd" | "equidistantPreserveStart" | number;
  layout?: "vertical" | "horizontal";
  legendPosition?: "left" | "center" | "right";
  maxValue?: number;
  minValue?: number;
  onValueChange?: (value: BarChartEventProps) => void;
  showGridLines?: boolean;
  showLegend?: boolean;
  showTooltip?: boolean;
  showXAxis?: boolean;
  showYAxis?: boolean;
  startEndOnly?: boolean;
  tickGap?: number;
  tooltipCallback?: (tooltipCallbackContent: TooltipProps) => void;
  type?: "default" | "stacked" | "percent";
  valueFormatter?: (value: number) => string;
  xAxisLabel?: string;
  yAxisLabel?: string;
  yAxisWidth?: number;
  formatXAxisTick?: XAxisProps["tickFormatter"];
  rotateXAxisTick?: boolean;
  mode?: "solid" | "gradient";
  defaultActiveBar?: any | undefined;
}

const BarChart = React.forwardRef<HTMLDivElement, BarChartProps>(
  (props, forwardedRef) => {
    const {
      data = [],
      categories = [],
      index,
      colors = AvailableChartColors,
      valueFormatter = (value: number) => value.toString(),
      startEndOnly = false,
      showXAxis = true,
      showYAxis = true,
      showGridLines = true,
      yAxisWidth = 56,
      intervalType = "equidistantPreserveStart",
      showTooltip = true,
      showLegend = true,
      autoMinValue = false,
      minValue,
      maxValue,
      allowDecimals = true,
      className,
      onValueChange,
      enableLegendSlider = false,
      barCategoryGap,
      tickGap = 5,
      xAxisLabel,
      yAxisLabel,
      layout = "horizontal",
      type = "default",
      legendPosition = "right",
      tooltipCallback,
      customTooltip,
      borderRadius,
      formatXAxisTick,
      rotateXAxisTick,
      mode = "solid",
      defaultActiveBar,
      ...other
    } = props;

    React.useEffect(() => {
      setActiveBar(defaultActiveBar);
    }, [defaultActiveBar]);

    const CustomTooltip = customTooltip;
    const paddingValue =
      (!showXAxis && !showYAxis) || (startEndOnly && !showYAxis) ? 0 : 20;
    const [legendHeight, setLegendHeight] = React.useState(60);
    const [activeLegend, setActiveLegend] = React.useState<string | undefined>(
      undefined,
    );
    const categoryColors = constructCategoryColors(categories, colors);
    const [activeBar, setActiveBar] = React.useState<any | undefined>(
      defaultActiveBar,
    );
    const yAxisDomain = getYAxisDomain(autoMinValue, minValue, maxValue);
    const hasOnValueChange = !!onValueChange;
    const stacked = type === "stacked" || type === "percent";

    const prevActiveRef = React.useRef<boolean | undefined>(undefined);
    const prevLabelRef = React.useRef<string | undefined>(undefined);

    function valueToPercent(value: number) {
      return `${(value * 100).toFixed(0)}%`;
    }

    function onBarClick(data: any, _: any, event: React.MouseEvent) {
      event.stopPropagation();
      if (!onValueChange) return;
      if (deepEqual(activeBar, { ...data.payload, value: data.value })) {
        setActiveLegend(undefined);
        setActiveBar(undefined);
        onValueChange?.(null);
      } else {
        setActiveLegend(data.tooltipPayload?.[0]?.dataKey);
        setActiveBar({
          ...data.payload,
          value: data.value,
        });
        onValueChange?.({
          eventType: "bar",
          categoryClicked: data.tooltipPayload?.[0]?.dataKey,
          ...data.payload,
        });
      }
    }

    function onCategoryClick(dataKey: string) {
      if (!hasOnValueChange) return;
      if (dataKey === activeLegend && !activeBar) {
        setActiveLegend(undefined);
        onValueChange?.(null);
      } else {
        setActiveLegend(dataKey);
        onValueChange?.({
          eventType: "category",
          categoryClicked: dataKey,
        });
      }
      setActiveBar(undefined);
    }

    return (
      <div
        ref={forwardedRef}
        className={cn("h-80 w-full", className)}
        tremor-id="tremor-raw"
        {...other}
      >
        <ResponsiveContainer>
          <RechartsBarChart
            data={data}
            onClick={
              hasOnValueChange && (activeLegend || activeBar)
                ? () => {
                    setActiveBar(undefined);
                    setActiveLegend(undefined);
                    onValueChange?.(null);
                  }
                : undefined
            }
            margin={{
              bottom: xAxisLabel ? 30 : rotateXAxisTick ? 40 : undefined,
              left: yAxisLabel ? 20 : undefined,
              right: yAxisLabel ? 5 : undefined,
              top: 5,
            }}
            stackOffset={type === "percent" ? "expand" : undefined}
            layout={layout}
            barCategoryGap={barCategoryGap}
          >
            {mode === "gradient" && (
              <defs>
                {categories.map((category) => (
                  <linearGradient
                    key={category}
                    id={getGradientId(
                      categoryColors.get(category) as AvailableChartColorsKeys,
                    )}
                    gradientTransform={"rotate(90)"}
                  >
                    {chartGradientColors[
                      categoryColors.get(
                        category,
                      ) as AvailableChartGradientsKeys
                    ]?.stops.map((stop, index) => (
                      <stop
                        // biome-ignore lint/suspicious/noArrayIndexKey: <bar stops no unique key>
                        key={index}
                        offset={stop.offset}
                        stopColor={stop.color}
                        stopOpacity="1"
                      />
                    ))}
                  </linearGradient>
                ))}
              </defs>
            )}
            {showGridLines ? (
              <CartesianGrid
                className={cn("stroke-gray-200 stroke-1 dark:stroke-gray-800")}
                horizontal={layout !== "vertical"}
                vertical={layout === "vertical"}
              />
            ) : null}
            <XAxis
              hide={!showXAxis}
              tick={
                rotateXAxisTick
                  ? (props) => (
                      <RotatedXAxisTick
                        {...props}
                        formatXAxisTick={formatXAxisTick}
                      />
                    )
                  : {
                      transform:
                        layout !== "vertical" ? "translate(0, 6)" : undefined,
                    }
              }
              fill=""
              stroke=""
              className={cn(
                // base
                "text-[9px]",
                // text fill
                "fill-gray-500 dark:fill-gray-500",
                { "mt-4": layout !== "vertical" },
              )}
              tickLine={false}
              axisLine={false}
              minTickGap={tickGap}
              tickFormatter={formatXAxisTick}
              {...(layout !== "vertical"
                ? {
                    padding: {
                      left: paddingValue,
                      right: paddingValue,
                    },
                    dataKey: index,
                    interval: startEndOnly ? "preserveStartEnd" : intervalType,
                    ticks: startEndOnly
                      ? //@ts-ignore
                        [data[0][index], data[data.length - 1][index]]
                      : undefined,
                  }
                : {
                    type: "number",
                    domain: yAxisDomain as AxisDomain,
                    tickFormatter:
                      type === "percent" ? valueToPercent : valueFormatter,
                    allowDecimals: allowDecimals,
                  })}
            >
              {xAxisLabel && (
                <Label
                  position="insideBottom"
                  offset={-20}
                  className="fill-gray-800 text-sm font-medium dark:fill-gray-200"
                >
                  {xAxisLabel}
                </Label>
              )}
            </XAxis>
            <YAxis
              width={yAxisWidth}
              hide={!showYAxis}
              axisLine={false}
              tickLine={false}
              fill=""
              stroke=""
              className={cn(
                // base
                "text-[9px]",
                // text fill
                "fill-gray-500 dark:fill-gray-500",
              )}
              tick={{
                transform:
                  layout !== "vertical"
                    ? "translate(-3, 0)"
                    : "translate(0, 0)",
              }}
              {...(layout !== "vertical"
                ? {
                    type: "number",
                    domain: yAxisDomain as AxisDomain,
                    tickFormatter:
                      type === "percent" ? valueToPercent : valueFormatter,
                    allowDecimals: allowDecimals,
                  }
                : {
                    dataKey: index,
                    ticks: startEndOnly
                      ? //@ts-ignore
                        [data[0][index], data[data.length - 1][index]]
                      : undefined,
                    type: "category",
                    interval: "equidistantPreserveStart",
                  })}
            >
              {yAxisLabel && (
                <Label
                  position="insideLeft"
                  style={{ textAnchor: "middle" }}
                  angle={-90}
                  offset={-15}
                  className="fill-gray-800 text-sm font-medium dark:fill-gray-200"
                >
                  {yAxisLabel}
                </Label>
              )}
            </YAxis>
            <Tooltip
              wrapperStyle={{ outline: "none" }}
              isAnimationActive={true}
              animationDuration={100}
              cursor={{ fill: "transparent", opacity: "0.15" }}
              offset={20}
              content={({ active, payload, label }) => {
                const cleanPayload: TooltipProps["payload"] = payload
                  ? payload.map((item: any) => ({
                      category: item.dataKey,
                      value: item.value,
                      index: item.payload[index],
                      color: categoryColors.get(
                        item.dataKey,
                      ) as AvailableChartColorsKeys,
                      type: item.type,
                      payload: item.payload,
                    }))
                  : [];

                if (
                  tooltipCallback &&
                  (active !== prevActiveRef.current ||
                    label !== prevLabelRef.current)
                ) {
                  tooltipCallback({ active, payload: cleanPayload, label });
                  prevActiveRef.current = active;
                  prevLabelRef.current = label;
                }

                return showTooltip && active ? (
                  CustomTooltip ? (
                    <CustomTooltip
                      active={active}
                      payload={cleanPayload}
                      label={label}
                    />
                  ) : (
                    <ChartTooltip
                      active={active}
                      payload={cleanPayload}
                      label={
                        formatXAxisTick ? formatXAxisTick(label, 0) : label
                      }
                      valueFormatter={valueFormatter}
                      mode={mode}
                    />
                  )
                ) : null;
              }}
            />
            {showLegend ? (
              <RechartsLegend
                verticalAlign="top"
                height={legendHeight}
                content={({ payload }) =>
                  ChartLegend(
                    { payload },
                    categoryColors,
                    setLegendHeight,
                    activeLegend,
                    hasOnValueChange
                      ? (clickedLegendItem: string) =>
                          onCategoryClick(clickedLegendItem)
                      : undefined,
                    enableLegendSlider,
                    legendPosition,
                    yAxisWidth,
                  )
                }
              />
            ) : null}
            {categories.map((category) => (
              <Bar
                className={cn(
                  getColorClassName(
                    categoryColors.get(category) as AvailableChartColorsKeys,
                    "fill",
                  ),
                  onValueChange ? "cursor-pointer" : "",
                )}
                key={category}
                name={category}
                type="linear"
                dataKey={category}
                stackId={stacked ? "stack" : undefined}
                isAnimationActive={true}
                animationDuration={1000}
                fill=""
                shape={(props: any) =>
                  renderShape(
                    props,
                    activeBar,
                    activeLegend,
                    layout,
                    mode,
                    categoryColors.get(category) as AvailableChartColorsKeys,
                    borderRadius,
                  )
                }
                onClick={onBarClick}
                maxBarSize={30}
              />
            ))}
          </RechartsBarChart>
        </ResponsiveContainer>
      </div>
    );
  },
);

BarChart.displayName = "BarChart";

//#region NoDataView

const NoDataView = ({ className }: { className: string }) => {
  return (
    <div className={cn("h-80 w-full relative", className)}>
      {/* SVG for simulating grid, axes, and labels */}
      <svg className="w-full h-full" role="presentation">
        {[...Array(6)].map((_, index) => {
          const yPosition = `${10 + index * 16}%`; // Y position for the grid line
          return (
            <g
              key={`h-grid-${
                // biome-ignore lint/suspicious/noArrayIndexKey: <no unique keys as it is a placeholder for no data>
                index
              }`}
            >
              {/* Static Text Label */}
              <text
                x="5%" // Position to the left of the grid line
                y={yPosition}
                dy="0.3em" // Center-align vertically with the line
                fontSize="10"
                fill="gray"
                opacity="0.6"
                textAnchor="middle"
              >
                0
              </text>
              {/* Grid Line */}
              <line
                x1="7%"
                x2="98%"
                y1={yPosition}
                y2={yPosition}
                stroke="gray"
                strokeWidth="0.7"
                strokeOpacity="0.1"
              />
            </g>
          );
        })}
      </svg>

      <div className="absolute inset-0 flex flex-col items-center justify-center text-gray-400">
        <ChartBar size={20} />
        <p className="text-[10px]">Oops! Graph data failed to load</p>
      </div>
    </div>
  );
};

//# region ChartSkeleton
const ChartSkeleton = ({ className }: { className: string }) => {
  return (
    <div className={cn("h-80 w-full relative", className)}>
      {/* SVG for simulating grid, axes, and labels */}
      <svg className="w-full h-full" role="presentation">
        {[...Array(6)].map((_, index) => {
          const yPosition = `${10 + index * 16}%`; // Y position for the grid line
          return (
            <g
              key={`h-grid-${
                // biome-ignore lint/suspicious/noArrayIndexKey: <no unique keys as it is a placeholder for no data>
                index
              }`}
            >
              {/* Static Text Label */}
              <text
                x="5%" // Position to the left of the grid line
                y={yPosition}
                dy="0.3em" // Center-align vertically with the line
                fontSize="10"
                fill="gray"
                opacity="0.6"
                textAnchor="middle"
              >
                0
              </text>
              {/* Grid Line */}
              <line
                x1="7%"
                x2="98%"
                y1={yPosition}
                y2={yPosition}
                stroke="gray"
                strokeWidth="0.7"
                strokeOpacity="0.1"
              />
            </g>
          );
        })}
      </svg>

      <div className={"flex absolute inset-0 justify-center items-center"}>
        <div className="flex items-end space-x-1 h-20 ">
          <div className="bg-gray-300 animate-pulse h-8 w-2 rounded-sm" />
          <div className="bg-gray-300  animate-pulse h-10 w-2 rounded-sm" />
          <div className="bg-gray-300 animate-pulse h-16 w-2 rounded-sm" />
          <div className="bg-gray-300 animate-pulse h-12 w-2 rounded-sm" />
        </div>
      </div>
    </div>
  );
};
export {
  BarChart,
  type BarChartEventProps,
  type TooltipProps,
  NoDataView,
  ChartSkeleton,
};
