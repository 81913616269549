import { cn } from "@/lib/utils";

interface CardProps {
  onClick?: () => void;
  className?: string;
  style?: React.CSSProperties;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  children: React.ReactNode;
}

const Card: React.FC<CardProps> = ({
  onClick,
  className,
  style,
  onMouseEnter,
  onMouseLeave,
  children,
}) => (
  // biome-ignore lint/a11y/useKeyWithClickEvents: <omitted for best practice on click event on headline>
  <div
    className={cn(
      "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-750",
      "px-6 bg-white rounded-lg border border-gray-200/60",
      "shadow-[0_2px_8px_0_rgba(0,0,0,0.04)]",
      className,
    )}
    style={style}
    tabIndex={-1}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {children}
  </div>
);

export default Card;
