import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/controls/command";
import type { NewsFeedPublisherType } from "@/data-access/news";
import { cn } from "@/lib/utils";
import { useEffect, useRef, useState } from "react";
import { Separator } from "../controls/separator";

interface PublisherSearchDropdownProps {
  publishers: NewsFeedPublisherType[];
  isLoading: boolean;
  onSearch: (term: string) => void;
  onSelect: (id: string) => void;
  selectedPublishers: Record<string, NewsFeedPublisherType[]>;
  multiSelect?: boolean;
  className?: string;
  autoOpen?: boolean;
  cancelEdit?: () => void;
}

const PublisherSearchDropdown = ({
  publishers,
  isLoading,
  onSearch,
  onSelect,
  selectedPublishers,
  multiSelect = true,
  className,
  autoOpen = false,
  cancelEdit,
}: PublisherSearchDropdownProps) => {
  const [commandListVisible, setCommandListVisible] = useState(false);
  const commandRef = useRef<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = useState("");
  const [isInteractingWithList, setIsInteractingWithList] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        commandRef.current &&
        !commandRef.current.contains(event?.target as Node)
      ) {
        setCommandListVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        commandRef.current &&
        !commandRef.current.contains(event?.target as Node)
      ) {
        setCommandListVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getFirstPublisherLogo = (publisher: NewsFeedPublisherType) => {
    const logos = publisher.logos.filter((logo) => logo.image?.url);
    return logos?.[0]?.image?.url ?? "";
  };

  const renderPublisherIcon = (publisher: NewsFeedPublisherType) => {
    const logo = getFirstPublisherLogo(publisher);
    const fallbackIconClass =
      "h-5 w-5 flex items-center justify-center text-[10px] text-gray-600 bg-gray-100 rounded";

    const getFallbackInitials = () => {
      const words = publisher.name.split(" ");
      return words.length > 1
        ? words
            .slice(0, 2)
            .map((word) => word[0])
            .join("")
            .toUpperCase()
        : publisher.name.substring(0, 2).toUpperCase();
    };

    const getFallbackIcon = () => {
      const icon = document.createElement("div");
      icon.className = fallbackIconClass;
      icon.textContent = getFallbackInitials();
      return icon;
    };

    const iconContent = logo ? (
      <img
        src={logo}
        alt={publisher.name}
        className="h-5 w-5 rounded object-contain"
        onLoad={(e) => {
          const img = e.target as HTMLImageElement;
          if (img.naturalWidth / img.naturalHeight !== 1) {
            img.style.display = "none";
            img.parentNode?.appendChild(getFallbackIcon());
          }
        }}
      />
    ) : (
      <div className={fallbackIconClass}>{getFallbackInitials()}</div>
    );

    return <div className="flex justify-center max-w-8">{iconContent}</div>;
  };

  const selectedPub = (publisher: NewsFeedPublisherType) =>
    Object.values(selectedPublishers).some((tiers) =>
      tiers.some((p) => p.id === publisher.id),
    );

  const renderPublisherItem = (
    publisher: NewsFeedPublisherType,
    key: string,
    searchable = true,
  ) => (
    <CommandItem
      key={key}
      value={key}
      onSelect={(value) => {
        const strippedValue = value.startsWith("s: ") ? value.slice(3) : value;
        onSelect(strippedValue);
        if (!multiSelect) {
          setCommandListVisible(false);
        }
      }}
      showIcon={selectedPub(publisher)}
      className={cn("gap-[7px] text-gray-900 text-xs")}
      keywords={searchable ? [publisher.name, publisher.url] : undefined}
      data-highlighted={selectedPub(publisher)}
    >
      {renderPublisherIcon(publisher)}
      <span className="whitespace-nowrap overflow-hidden text-ellipsis">
        {publisher.name}
      </span>
      <span className="text-[10px] text-gray-300 whitespace-nowrap overflow-hidden text-ellipsis">
        {publisher.url}
      </span>
    </CommandItem>
  );

  const renderSelectedPublishers = () => {
    const selected = Object.values(selectedPublishers).flat();
    if (!selected.length) return null;

    return (
      <>
        {selected.map((publisher) =>
          publisher
            ? renderPublisherItem(publisher, `s: ${publisher.id}`, false)
            : null,
        )}
        {!searchValue && <Separator className="my-1" />}
      </>
    );
  };

  const renderUnselectedPublishers = () => {
    const selectedIds = Object.values(selectedPublishers)
      .flat()
      .map((p) => p.id);

    // In single select mode, filter out the selected publisher
    const filteredPublishers = !multiSelect
      ? publishers.filter((pub) => !selectedIds.includes(pub.id))
      : publishers;

    return filteredPublishers.map((publisher) =>
      renderPublisherItem(publisher, publisher.id),
    );
  };

  return (
    <Command
      className={cn("relative overflow-visible", className)}
      ref={commandRef}
      filter={(_, search, keywords) => {
        const extendedValue = `${keywords ?? [].join(" ")}`;
        const trimmedSearch = search.trim().toLowerCase();
        return extendedValue.toLowerCase().includes(trimmedSearch) ? 1 : 0;
      }}
    >
      <CommandInput
        onFocus={() => setCommandListVisible(true)}
        placeholder="Search publications..."
        onValueChange={(value) => {
          setSearchValue(value);
          onSearch(value);
        }}
        loading={isLoading}
        autoFocus={autoOpen}
        onBlur={() => {
          if (!isInteractingWithList && cancelEdit) {
            cancelEdit();
          }
        }}
      />
      <CommandList
        className={cn(
          commandListVisible ? "absolute" : "hidden",
          "bg-white",
          "border rounded-md shadow-md",
          "scrollbar-thin",
          "top-10",
          "z-50",
        )}
        onMouseEnter={() => setIsInteractingWithList(true)}
        onMouseLeave={() => setIsInteractingWithList(false)}
      >
        <CommandEmpty className="text-gray-600 w-[342px] py-2 text-xs px-4">
          {isLoading ? "Searching publications..." : "No results found."}
        </CommandEmpty>
        <CommandGroup>
          {renderSelectedPublishers()}
          {renderUnselectedPublishers()}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};

export default PublisherSearchDropdown;
