import { cn } from "@/lib/utils";

interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: "default" | "avatar" | "card" | "text";
  animate?: "pulse" | "wave";
  width?: string | number;
  height?: string | number;
}

function Skeleton({
  className,
  variant = "default",
  animate = "pulse",
  width,
  height,
  ...props
}: SkeletonProps) {
  const variants = {
    default: "rounded-md",
    avatar: "rounded-full",
    card: "rounded-lg",
    text: "rounded h-4 w-full",
  };

  const animations = {
    pulse: "animate-pulse",
    wave: "animate-shimmer bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 dark:from-gray-800 dark:via-slate-700 dark:to-slate-800 bg-[length:400%_100%]",
  };

  return (
    <div
      className={cn(
        variants[variant],
        animations[animate],
        "bg-gray-100 dark:bg-gray-800",
        className,
      )}
      style={{
        width: width,
        height: height,
      }}
      {...props}
    />
  );
}

export { Skeleton };
